<template>
    <div class="wrapper">
        <div class="wrapperHeader">
            <div class="wrapIconHeader" @click="$router.go(-1)">
                <font-awesome-icon icon="chevron-left" />
            </div>
            <h3>
                Tambah Pemeriksaan
            </h3>
        </div>
    </div>
    <div class="header">
        <div class="search-bar-container" id="searchbar-container">
          <div class="search-input-container">
              <input v-model="searchTerm" type="text" placeholder="Cari nama anak" 
                class="search-input" @input="handleInput">
              <button v-if="searchTerm == ''" class="search-icon" @click="searchTerm = ''">
                  <font-awesome-icon icon="search" />
              </button>
              <button v-else class="search-icon" @click="clearSearch">
                  <font-awesome-icon icon="close" />
              </button>
          </div>
      </div>
    </div>
    <div class="infinite-scroll-list" ref="scrollContainer" @scroll="handleScroll">
        <ul>
          <li v-for="patient in dataPatient" :key="patient.medicalRecordNumber" 
          @click="redirectToIsi(patient)" style="list-style: none;">
            <div class="rowWrapper">
              <div class="rowImage">
                <v-img src="@/assets/default_profile.png" class="imgCenter" alt="" cover>
                </v-img>
              </div>
              <div class="rowContent">
                <li style="list-style: none;">
                  <h3>{{ patient.patientFullName }}</h3>
                  <p v-if="patient.gender == 'MALE'" style="color: #8692A6; font-size: 12px;">Laki-laki <font-awesome-icon icon="circle" class="icon-grey-small" />  {{ this.formatedDate(patient.birthDate) }}</p>
                  <p v-else style="color: #8692A6; font-size: 12px;">Perempuan - {{ this.formatedDate(patient.birthDate) }}</p>
                </li>
              </div>
            </div>
            <v-divider></v-divider>
          </li>
        </ul>
        <div v-if="loading" class="loading-indicator">
          <v-img src="@/assets/loading.gif" class="loading-style" alt="Loading..."></v-img>
        </div>
        <div v-else-if="isSearch" class="loading-indicator">
          <p style="font-size: 14px; text-align: center; display: inline-flex;">Tidak ada data.</p>
        </div>
    </div>
    <v-dialog v-model="isShowDialog" maxWidth="480px">
        <v-card>
            <v-card-title style="text-align: center; margin-top: 10px;">
                Informasi
            </v-card-title>
            <v-card-text>
            <div>
                <p style="font-size: 12px; margin-left: 10px; margin-right: 10px; text-align: center;">
                    Terdapat data pertumbuhan balita pada bulan ini, tap "Lanjut" jika ingin update data pertumbuhan balita.
                </p>
            </div>
            <br />
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
                <v-spacer></v-spacer> <!-- This spacer will push buttons to the right -->
                <v-btn class="custom-btn" @click="closeDialog">Batal</v-btn>
                <v-btn class="custom-btn-success" @click="submitForm">Lanjut</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <div class="center-container" v-if="isShowLoading">
        <v-img src="@/assets/loading.gif" class="loading-style" alt="Loading..."></v-img>
    </div>
</template>

<script>
    import { ref } from 'vue'
    import moment from 'moment'
    import ApiServices from '@/service/ApiServices'

    export default {
        setup() {
            const dataPatient = ref([])
            return { dataPatient }
        },
        data() {
            return { 
                searchTerm: '',
                currentPage: 0,
                loading: false,
                isLastPage: false,
                typingTimer: null,
                isShowDialog: false,
                isShowLoading: false,
                isSearch: false,
                tanggalMeasurement: moment(new Date()).format('yyyy-MM-DD'),
                name: '',
                gender: '',
                birth: '',
                kpi: '',
                mdr: '',
                gmi: '',
                hci: '',
                bb: 0,
                pb: 0,
                ll: 0,
                lk: 0,
                asi: '',
            }
        },
        methods: {
            async searchPatient() {
                if(this.searchTerm == '') {
                    this.dataPatient = []
                    this.isSearch = false;
                } else {
                    this.loading = true;
                    ApiServices.searchHistoryPatient(0, this.searchTerm).then((res) => {
                        this.dataPatient = []
                        this.dataPatient = ref(res.data.data)
                        
                        this.currentPage++
                        if(this.dataPatient.length < 20) {
                            this.isLastPage = true;
                        } else {
                            this.isLastPage = false;
                        }
                        this.loading = false;
                        if(this.dataPatient.length == 0) {
                            this.isSearch = true;
                        } else {
                            this.isSearch = false;
                        }
                    }).catch(error => {
                        this.dataPatient = []
                        this.isSearch = true
                        console.error(error)
                        this.loading = false;
                    })
                }
            },
            handleScroll(event) {
                const scrollContainer = event.target;
                if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) { 
                this.loadMoreData();
                }
            },
            async loadMoreData() {
                if(!this.isLastPage) {
                try {
                    ApiServices.searchHistoryPatient(this.currentPage, this.searchTerm).then((res) => {
                        this.dataPatient = this.dataPatient.concat(res.data.data.content)
                        this.currentPage++
                        if(res.data.data.content.length < 20) {
                            this.isLastPage = true;
                        } else {
                            this.isLastPage = false;
                        }
                    }).catch(error => {
                        console.error(error)
                    })
                } catch (error) {
                    console.error('Error loading next page:', error);
                }
                }
            },
            redirectToIsi(dt) {
                this.isShowLoading = true
                this.name = dt.patientFullName
                this.gender = dt.gender
                this.birth = dt.birthDate
                this.kpi = dt.klinisiaPatientId
                this.mdr = dt.medicalRecordNumber
                ApiServices.getHistoryMonth(dt.medicalRecordNumber, this.tanggalMeasurement).then((res) => {
                    if(res.data.status == 'OK') {
                        this.tanggalMeasurement = res.data.data['measurementDate']
                        this.gmi = res.data.data['growthMeasurementId'],
                        this.hci = res.data.data['healthCenterId'],
                        this.bb = res.data.data['weightBodyKg'],
                        this.pb = res.data.data['heightBodyCm'],
                        this.ll = res.data.data['armCircumferenceCm'],
                        this.lk = res.data.data['headCircumferenceCm'],
                        this.asi = res.data.data['isExclusiveBreastfeeding']
                        this.openModal()
                    } else {
                        this.isShowLoading = false;
                        this.$router.push({
                            path: '/isiPemeriksaan',
                            query: { 
                                dates: '',
                                names: this.name,
                                genders: this.gender,
                                births: this.birth,
                                kpis: this.kpi,
                                mdrs: this.mdr,
                                gmi: this.gmi,
                                hci: this.hci,
                                bb: this.bb,
                                pb: this.pb,
                                ll: this.ll,
                                lk: this.lk,
                                asi: this.asi
                            }
                        })
                    }
                }).catch(error => {
                    this.isShowLoading = false
                    console.log(error)
                    this.$router.push({
                        path: '/isiPemeriksaan',
                        query: { 
                            dates: '',
                            names: this.name,
                            genders: this.gender,
                            births: this.birth,
                            kpis: this.kpi,
                            mdrs: this.mdr,
                            gmi: this.gmi,
                            hci: this.hci,
                            bb: this.bb,
                            pb: this.pb,
                            ll: this.ll,
                            lk: this.lk,
                            asi: this.asi
                        }
                    })
                })
            },
            formatedDate(tanggal) {
                var dates = new Date(tanggal);
                const options = {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                };
                return dates.toLocaleDateString('id-ID', options);
            },
            formatedLongDate(tanggal) {
                var dates = new Date(tanggal);
                const options = {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                };
                return dates.toLocaleDateString('id-ID', options);
            },
            handleInput() {
                this.currentPage = 0
                this.isLastPage = false
                // Clear any existing timer
                clearTimeout(this.typingTimer);

                // Set a new timer to detect when typing has finished
                this.typingTimer = setTimeout(this.searchPatient, 500); // Adjust the delay as needed
            },
            openModal() {
                this.isShowDialog = true;
            },
            closeModal() {
                this.isShowDialog = false;
            },
            closeDialog() {
                this.isShowDialog = false;
                this.isShowLoading = false;
            },
            submitForm() {
                this.$router.replace({
                    path: '/isiPemeriksaan',
                    query: { 
                        dates: this.tanggalMeasurement,
                        names: this.name,
                        genders: this.gender,
                        births: this.birth,
                        kpis: this.kpi,
                        mdrs: this.mdr,
                        gmi: this.gmi,
                        hci: this.hci,
                        bb: this.bb,
                        pb: this.pb,
                        ll: this.ll,
                        lk: this.lk,
                        asi: this.asi
                    }
                })
            },
            clearSearch() {
                this.searchTerm = ''
                this.dataPatient = []
                this.isSearch = false
            }
        }
    }
</script>

<style scoped>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        color: #2c3e50;
        margin-top: 10px;
    }

    .wrapper {
        max-width: 960px;
        padding: 20px;
        margin: 0 auto;
        font-size: .8rem;
    }

    .infinite-scroll-list {
        max-width: 960px;
        margin: 0 auto;
        min-height: 400px;
        max-height: 750px;
        overflow-y: auto;
        padding: 10px 20px 10px 20px;
    }
    .rowWrapper {
        display: flex;
        width: 100%;
        margin: 10px 0;
    }
    .rowImage {
        align-items: center;
        width: 42px;
        height: 42px;
    }
    .rowContent {
        margin-left: 20px;
    }
    .icon-grey {
        color: #8692A6;
        margin-right: 5px;
        margin-top: 2px;
    }
    .icon-grey-small {
        color: #8692A6;
        margin: 0px 2px 4px 2px;
        width: 3px;
        height: 3px;
    }

    .center-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999; /* Ensure it's above other elements */
    }

    .loading-style {
        height: 50px;
        width: 50px;
        display: inline-flex;
    }

    .custom-btn {
        border: 1px solid lightgray;
        border-radius: 8px;
        background-color: transparent;
        color: gray;
        padding: 8px 30px;
        margin: 8px;
        text-transform: none;
        transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Add smooth transitions */
    }

    .custom-btn-success {
        background-color: #00CC6A;
        border-radius: 8px;
        color: white;
        padding: 8px 30px;
        margin: 8px;
        text-transform: none;
        transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Add smooth transitions */
    }
</style>