import axios from 'axios'

// dev api url 
// const API_URL = 'https://api-kader-stg.eposyandu.id/'

// prod api url
const API_URL = 'https://api-kader.eposyandu.id/'

class ApiServices {
    async validateTokenServer(healthCenterId) {
        const token = localStorage.getItem('authToken')
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token

        return await axios.post(API_URL + 'v1/auth/'+healthCenterId+'/verify', {})
    }
    
    async getDataPemeriksaan(page, mdr) {
        const token = localStorage.getItem('authToken')
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token
        
        return await axios.get(API_URL + 'v1/growth-measurement?medicalRecordNumber='+mdr+'&page='+page+'&itemPerPage=20')

    }

    async getHistoryPemeriksaan(page, pName) {
        const token = localStorage.getItem('authToken')
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token
        
        return await axios.get(API_URL + 'v1/growth-measurement/patient-history?page='
            +page+'&itemPerPage=20&patientFullName='+pName)

    }

    async getDataKMS(mdr) {
        const token = localStorage.getItem('authToken')
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token
        
        return await axios.get(API_URL + 'v1/growth-measurement/kms?medicalRecordNumber='+mdr)
    }

    async getDetailPemeriksaan(queryParam) {
        const token = localStorage.getItem('authToken')
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token
        
        return await axios.get(API_URL + 'v1/growth-measurement/' + queryParam)
    }

    async searchHistoryPatient(page, name) {
        const token = localStorage.getItem('authToken')
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token
        
        return await axios.get(API_URL + 'v1/patient?page='+page+'&itemPerPage=10&patientFullName='+name)
    }

    async getHistoryMonth(mrNumber, date) {
        const token = localStorage.getItem('authToken')
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token
        
        return await axios.get(API_URL + 'v1/growth-measurement/this-month?medicalRecordNumber='+mrNumber+'&measurementDate='+date)
    }

    async postPemeriksaan(params) {
        const token = localStorage.getItem('authToken')
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token
        
        return await axios.post(API_URL + 'v1/growth-measurement', params)
    }

    async updatePemeriksaan(params, gmi) {
        const token = localStorage.getItem('authToken')
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        axios.defaults.headers.common['Authorization'] = 'Bearer '+token
        
        return await axios.put(API_URL + 'v1/growth-measurement/' + gmi, params)
    }
}

export default new ApiServices()