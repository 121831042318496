<template>
    <div class="wrapper">
      <h3>
        Riwayat Pemeriksaan
      </h3>
      <div class="search-bar-container" id="searchbar-container">
          <div class="search-input-container">
              <input v-model="searchTerm" type="text" placeholder="Cari nama" 
                class="search-input" @input="handleInput">
              <button v-if="searchTerm == ''" class="search-icon" @click="searchTerm = ''">
                  <font-awesome-icon icon="search" />
              </button>
              <button v-else class="search-icon" @click="clearSearch">
                  <font-awesome-icon icon="close" />
              </button>
          </div>
      </div>
      <div class="infinite-scroll-list" ref="scrollContainer" @scroll="handleScroll">
        <div v-if="loading" class="loading-indicator">
          <v-img src="@/assets/loading.gif" class="loading-style" alt="Loading..."></v-img>
        </div>
        <div v-else-if="dataPatient.length == 0" class="loading-indicator">
          <p style="font-size: 14px; text-align: center; display: inline-flex;">Tidak ada data.</p>
        </div>
        <ul v-else>
          <li v-for="patient in dataPatient" :key="patient.medicalRecordNumber" 
          @click="handleClickItem(patient)" style="list-style: none;">
            <div class="rowWrapper">
              <div class="rowImage">
                <v-img src="@/assets/default_profile.png" class="imgCenter" alt="" cover>
                </v-img>
              </div>
              <div class="rowContent">
                <li style="list-style: none;">
                  <h3>{{ patient.patientFullName }}</h3>
                  <p v-if="patient.gender == 'MALE'" style="color: #8692A6;">Laki-laki <font-awesome-icon icon="circle" class="icon-grey-small" />  {{ this.changeAgePatient(patient.measurementDate,patient.birthDate) }}</p>
                  <p v-else style="color: #8692A6;">Perempuan <font-awesome-icon icon="circle" class="icon-grey-small" /> {{ this.changeAgePatient(patient.measurementDate,patient.birthDate) }}</p>
                  <p style="color: black; margin-top: 2px;"><font-awesome-icon icon="fa-regular fa-clock" class="icon-grey" /> {{ this.formatedLongDate(patient.measurementDate) }}</p>
                </li>
              </div>
            </div>
            <v-divider></v-divider>
          </li>
        </ul>
      </div>
    </div>
    <footer id="footer-wrapper">
      <li style="list-style: none;">
        <div class="containerButton">
          <button @click="redirectToAdd" class="submit-button" style="color: white;">
            Tambah Pemeriksaan Baru
          </button>
        </div>
      </li>
    </footer>
  </template>
  
  <script>
  import { ref } from 'vue'
  import ApiServices from '@/service/ApiServices'
  
  export default {
    setup() {
      const dataPatient = []
          return { dataPatient }
    },
    data() {
      return { 
        loading: true,
        searchTerm: '',
        typingTimer: null,
        currentPage: 0,
        itemPerPage: 20,
        isLastPage: false
      }
    },
    methods: {
      async getHistory() {
        this.loading = true;
        ApiServices.getHistoryPemeriksaan(this.currentPage, this.searchTerm).then((res) => {
          // console.log(res.data.data)
          this.dataPatient = []
          this.dataPatient = ref(res.data.data.content)
          
          if(this.dataPatient.length < 20) {
            this.isLastPage = true;
          } else {
            this.isLastPage = false;
          }
          this.loading = false;
        }).catch(error => {
          this.dataPatient = []
          console.error(error)
          this.loading = false;
        })
      },
      handleScroll(event) {
        const scrollContainer = event.target;
        if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) { 
          this.loadMoreData();
        }
      },
      async loadMoreData() {
        if(!this.isLastPage) {
          try {
            this.currentPage++
            ApiServices.getHistoryPemeriksaan(this.currentPage, this.searchTerm).then((res) => {
              this.dataPatient = this.dataPatient.concat(res.data.data.content)
              
              if(res.data.data.content.length < 20) {
                this.isLastPage = true;
              } else {
                this.isLastPage = false;
              }
            }).catch(error => {
              console.error(error)
            })
          } catch (error) {
            console.error('Error loading next page:', error);
          }
        }
      },
      redirectToAdd() {
        this.$router.push('/tambahPemeriksaan');
      },
      handleClickItem(data) {
        this.$router.push({
          name: 'hasilPemeriksaan',
          query: {
            healthCenterId: data.healthCenterId,
            medicalRecordNumber: data.medicalRecordNumber,
            patientFullName: data.patientFullName,
            birthDate: data.birthDate,
            gender: data.gender,
            measurementDate: data.measurementDate
          }
        });
      },
      formatedDate(tanggal) {
        var dates = new Date(tanggal);
        const options = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        };
        return dates.toLocaleDateString('id-ID', options);
      },
      formatedLongDate(tanggal) {
        var dates = new Date(tanggal);
        const options = {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        };
        return dates.toLocaleDateString('id-ID', options);
      },
      handleInput() {
        this.currentPage = 0;
        this.isLastPage = false;
        // Clear any existing timer
        clearTimeout(this.typingTimer);

        // Set a new timer to detect when typing has finished
        this.typingTimer = setTimeout(this.getHistory, 500); // Adjust the delay as needed
      },
      changeAgePatient(tanggal, birth) {
        if(tanggal != null) {
            const toDate = new Date(tanggal);
            const fromDate = new Date(birth);
            const age = this.calculateAgeOfMonth(fromDate, toDate);
            return age.totalMonths + ' Bulan'
        } else {
          return '0 Bulan'
        }
      },
      calculateAgeOfMonth(fromDate, toDate) {
        var fromYear = fromDate.getFullYear();
        var fromMonth = fromDate.getMonth();
        var toYear = toDate.getFullYear();
        var toMonth = toDate.getMonth();

        var years = toYear - fromYear;
        var months = toMonth - fromMonth;

        if (months < 0) {
            years--;
            months += 12;
        }

        var days = toDate.getDate() - fromDate.getDate();
        if(days >= 30) {
            months++;
        } else if(days < 0) {
            months--;
        }

        if(months < 0) {
            years--;
            months += 12;
        } else if(months > 12) {
            years++;
        } else if(months == 12) {
            years++;
            months = 0;
        }

        return {
            years: years,
            months: months,
            days: days,
            totalMonths: years * 12 + months
        };
      },
      clearSearch() {
        this.searchTerm = ''
        this.getHistory()
      }
    },
    mounted() {
      this.getHistory()
    },
  }
  </script>
  
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: #2c3e50;
    margin-top: 10px;
  }
  .rowWrapper {
    display: flex;
    width: 100%;
    margin: 10px 0;
  }
  .rowImage {
    align-items: center;
    width: 42px;
    height: 42px;
  }
  .rowContent {
    margin-left: 20px;
  }
  .wrapperSearchBar {
    max-width: 960px;
    min-width: 350px;
  }
  .searchBar {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px 10px 8px 10px;
    height: 55;
    border: 1px solid lightgrey;
    border-radius: 8px;
  }
  .wrapper {
    max-width: 960px;
    padding: 20px;
    margin: 0 auto;
    font-size: .8rem;
  }

  .search-bar-container {
    width: 100%;
    margin: 0 auto;
    padding: 10px 0px 10px 0px;
    box-sizing: border-box;
  }

  .search-input-container {
    display: flex;
    align-items: center;
    border: 1px solid #CFD4E1;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
  }

  .search-input {
    flex: 1;
    padding: 10px;
    font-size: 14px;
    border: none;
    background-color: white;
  }

  .search-input:focus {
    border: none;
    outline: none;
  }

  .search-icon {
    border: none;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }

  .search-icon i {
    font-size: 18px;
  }

  .search-results {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .search-results li {
    padding: 5px;
    border-bottom: 1px solid #ccc;
  }

  .submit-button {
    background-color: #00CC6A;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 20px;
    cursor: pointer;
    width: 100%;
    font-weight: bold;
    font-size: 18sp;
  }

  footer {
    bottom: 0;
    width: 100%;
    position: sticky;
    z-index: 9999;
    color: white;
    max-width: 960px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
    font-size: .8rem;
  }

  .containerButton {
    padding: 20px;
  }

  .infinite-scroll-list {
    width: 100%;
    height: 550px ;
    overflow-y: auto;
    padding: 10px;
  }

  .loading-indicator {
    text-align: center;
    margin-top: 150px;
    padding: 10px;
    color: #888;
  }

  .icon-grey {
    color: #8692A6;
    margin-right: 5px;
    margin-top: 2px;
  }
  .icon-grey-small {
    color: #8692A6;
    margin: 0px 2px 4px 2px;
    width: 3px;
    height: 3px;
  }
  .loading-style {
    height: 50px;
    width: 50px;
    display: inline-flex;
  }

  @media only screen and (max-width: 480px) {
    .infinite-scroll-list {
      width: 100%;
      height: 500px ;
      overflow-y: auto;
      padding: 10px;
    }      
  }
</style>