import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch, faChevronLeft, faChevronRight, 
  faCheck, faClose, faCalendarAlt, faCircle, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { faClock, faCircleQuestion, faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { loadFonts } from './plugins/webfontloader'
import 'vuetify/dist/vuetify.min.css';

loadFonts()
library.add(faSearch)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faCheck)
library.add(faClose)
library.add(faCalendarAlt)
library.add(faClock)
library.add(faCircle)
library.add(faCircleInfo)
library.add(faCircleQuestion)
library.add(faQuestionCircle)

const apps = createApp(App)
  .use(router)
  .use(vuetify)
  .component('font-awesome-icon', FontAwesomeIcon)

apps.config.devtools = true;
apps.config.productionTip = false;
apps.mount('#app')