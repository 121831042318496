<template>
    <div class="wrapper">
        <div class="wrapperHeader">
            <div class="wrapIconHeader" @click="$router.go(-1)">
                <font-awesome-icon icon="chevron-left" />
            </div>
            <h3>
                Detail Pemeriksaan
            </h3>
        </div>
    </div>
    <div v-if="!loading">
        <div class="header">
            <div class="rowHeaderWrap">
                <div class="rowHeaderWrapImage">
                    <v-img src="@/assets/default_profile.png" class="imgCenter" alt="" cover></v-img>
                </div>
                <div class="rowHeaderWrapContent">
                    <li>
                        <h4>{{ dataPemeriksaan['patientFullName'] }}</h4>
                        <p v-if="dataPemeriksaan['gender'] == 'MALE'">Laki-Laki - {{ this.formatedDate(dataPemeriksaan['birthDate']) }}</p>
                        <p v-else>Perempuan - {{ this.formatedDate(dataPemeriksaan['birthDate']) }}</p>
                    </li>
                </div>
            </div>
        </div>
        <div style="margin-top: 10px;"></div>
        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Tanggal Pemeriksaan</td>
                    </tr>
                    <tr>
                        <td><h3>{{ dataPemeriksaan['measurementDate'] }}</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Usia Anak</td>
                    </tr>
                    <tr>
                        <td>
                            <h3 v-if="dataPemeriksaan.age.years > 0 && dataPemeriksaan.age.totalMonths > 0 && dataPemeriksaan.age.months > 0">{{ dataPemeriksaan.age.totalMonths }} Bulan atau {{ dataPemeriksaan.age.years }} Tahun {{ dataPemeriksaan.age.months }} Bulan</h3>
                            <h3 v-else-if="dataPemeriksaan.age.years > 0 && dataPemeriksaan.age.totalMonths > 0 && dataPemeriksaan.age.months <= 0">{{ dataPemeriksaan.age.totalMonths }} Bulan atau {{ dataPemeriksaan.age.years }} Tahun</h3>
                            <h3 v-else>{{ dataPemeriksaan.age.totalMonths }} Bulan</h3>
                        </td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Berat Badan</td>
                    </tr>
                    <tr>
                        <td><h3>{{ dataPemeriksaan.weightBodyKg }} kg</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item" v-if="dataPemeriksaan.age.totalMonths > 24">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Tinggi Badan</td>
                    </tr>
                    <tr>
                        <td><h3>{{ dataPemeriksaan.heightBodyCm }} cm</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item" v-else>
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Panjang Badan</td>
                    </tr>
                    <tr>
                        <td><h3>{{ dataPemeriksaan.heightBodyCm }} cm</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Lingkar Lengan</td>
                    </tr>
                    <tr>
                        <td><h3>{{ dataPemeriksaan.armCircumferenceCm }} cm</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Lingkar Kepala</td>
                    </tr>
                    <tr>
                        <td><h3>{{ dataPemeriksaan.headCircumferenceCm }} cm</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Kenaikan Berat Minimal (KBM)</td>
                    </tr>
                    <tr>
                        <td v-if="dataPemeriksaan.kbm != null"><h3>{{ dataPemeriksaan.kbm }} g</h3></td>
                        <td v-else><h3>-</h3></td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">N/T (Naik atau Tidak)</td>
                    </tr>
                    <tr>
                        <td>
                            <h3 style="color: #18AF7C;" v-if="dataPemeriksaan.growthStatus == 'Naik'">{{ dataPemeriksaan.growthStatus }}</h3>
                            <h3 style="color: #D3555C;" v-else>{{ dataPemeriksaan.growthStatus }}</h3>
                        </td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Status Gizi (BB/U)</td>
                    </tr>
                    <tr>
                        <td>
                            <h3 style="color: #18AF7C;" v-if="dataPemeriksaan.bbPerUmNutritionalStatusCategory == 'BB Normal'">{{ dataPemeriksaan.bbPerUmNutritionalStatusCategory }}</h3>
                            <h3 style="color: #FE9800;" v-else-if="dataPemeriksaan.bbPerUmNutritionalStatusCategory == 'BB Kurang'">{{ dataPemeriksaan.bbPerUmNutritionalStatusCategory }}</h3>
                            <h3 style="color: #D3555C;" v-else>{{ dataPemeriksaan.bbPerUmNutritionalStatusCategory }}</h3>
                        </td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Status Gizi (PB/U)</td>
                    </tr>
                    <tr>
                        <td>
                            <h3 style="color: #18AF7C;" v-if="dataPemeriksaan.pbPerUmOrTbPerUmNutritionalStatusCategory == 'Normal' || dataPemeriksaan.pbPerUmOrTbPerUmNutritionalStatusCategory == 'Tinggi'">{{ dataPemeriksaan.pbPerUmOrTbPerUmNutritionalStatusCategory }}</h3>
                            <h3 style="color: #FE9800;" v-else-if="dataPemeriksaan.pbPerUmOrTbPerUmNutritionalStatusCategory == 'Pendek'">{{ dataPemeriksaan.pbPerUmOrTbPerUmNutritionalStatusCategory }}</h3>
                            <h3 style="color: #D3555C;" v-else>{{ dataPemeriksaan.pbPerUmOrTbPerUmNutritionalStatusCategory }}</h3>
                        </td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
        </div>

        <div class="grid-container">
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Status Gizi (BB/PB)</td>
                    </tr>
                    <tr>
                        <td>
                            <h3 style="color: #18AF7C;" v-if="dataPemeriksaan.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Gizi Baik'">{{ dataPemeriksaan.bbPerPbOrBbPerTbNutritionalStatusCategory }}</h3>
                            <h3 style="color: #FE9800;" v-else-if="dataPemeriksaan.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Gizi Kurang' || dataPemeriksaan.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Gizi Lebih' || dataPemeriksaan.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Beresiko Gizi Lebih'">{{ dataPemeriksaan.bbPerPbOrBbPerTbNutritionalStatusCategory }}</h3>
                            <h3 style="color: #D3555C;" v-else>{{ dataPemeriksaan.bbPerPbOrBbPerTbNutritionalStatusCategory }}</h3>
                        </td>
                    </tr>
                </table>
                <v-divider style="padding-top: 5px;"></v-divider>
            </div>
            <div class="grid-item">
                <table style="width: 100%;">
                    <tr>
                        <td colspan="3">Asi Eksklusif</td>
                    </tr>
                    <tr>
                        <td>
                            <h3 v-if="dataPemeriksaan.isExclusiveBreastfeeding">Ya</h3>
                            <h3 v-else>Tidak</h3>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div v-else class="loading-indicator">
        <v-img src="@/assets/loading.gif" class="loading-style" alt="Loading..."></v-img>
    </div>
    <br />
  </template>
  
  <script>
  import { ref } from 'vue'
  import ApiServices from '@/service/ApiServices'

  export default {
    setup() {
        const dataPemeriksaan = ref({})
        return { dataPemeriksaan }
    },
    data() {
        const id = this.$route.params.growthMeasurementId
        return {
            growthMeasurementId: id,
            loading: true
        };
    },
    methods: {
        getDetailData() {
            ApiServices.getDetailPemeriksaan(this.growthMeasurementId)
                .then((response) => {
                    this.dataPemeriksaan = {}
                    this.dataPemeriksaan = ref(response.data.data)
                    this.loading = false;
                }).catch(error => {
                    console.log(error)
                    this.loading = false;
                })
        },
        formatedDate(tanggal) {
            var dates = new Date(tanggal);
            const options = {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            };
            return dates.toLocaleDateString('id-ID', options);
        }
    },
    mounted() {
        this.getDetailData()
    }
  };
  </script>
  
  <style scoped>
  .loading-indicator {
    text-align: center;
    margin-top: 150px;
    padding: 10px;
    color: #888;
  }

  .loading-style {
    height: 50px;
    width: 50px;
    display: inline-flex;
  }

  .grid-container {
    display: flex;
    flex-wrap: wrap; /* This ensures items wrap to the next line on smaller screens */
    width: 100%;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 960px;
    margin: 0 auto;
    font-size: .8rem;
    overflow-y: auto;
  }
  
  .grid-item {
    flex: 1;
    min-width: 300px; /* Adjust as needed */
    padding-left: 10px;
    padding-right: 10px;
    font-size: .8rem;
  }

  .input-container {
        width: 100%;
        display: flex;
        border: 1px solid lightgrey;
        background-color: rgba(243, 241, 239, 0.5);
        border-radius: 10px;
        overflow: hidden;
    }
    .input-parent-container {
        width: 100%;
        display: flex;
        overflow: hidden;
    }
    .input-container-other {
        width: 92%;
        display: flex;
        border: 1px solid lightgrey;
        background-color: rgba(243, 241, 239, 0.5);
        border-radius: 10px;
        overflow: hidden;
    }
    .input-container-satuan {
        width: 8%;
        display: flex;
        overflow: hidden;
        padding-left: 5px;
        margin-top: 10px;
    }
    .input-container-radio {
        width: 45%;
        display: inline-flex;
        border: 1px solid lightgrey;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
        padding: 8px;
        margin-right: 10px;
    }
    .radio-style {
        margin-top: 0px;
    }

    .radio-style:checked:disabled + label {
        accent-color: red;
    }

    .input-container-radio p {
        margin: 0px 8px 0px 8px;
        padding-top: 2px;
    }
    .background-input {
        width: 100%;
        padding: 8px 10px 8px 15px;
        font-size: 14px;
        border: none;
        background-color: rgba(243, 241, 239, 0.5);
    }
    .background-input:focus {
        border: none;
        outline: none;
    }

    .input-icon {
        border: none;
        padding: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(243, 241, 239, 0.5);
    }

    .icon-color-primary {
        color: #2B92E4;
    }

    @media only screen and (max-width: 640px) {
        .input-container-radio {
            width: 45%;
            display: inline-flex;
            border: 1px solid lightgrey;
            background-color: white;
            border-radius: 10px;
            overflow: hidden;
            padding: 8px;
            margin-right: 10px;
        }
    }
  </style>  