<template>
    <div class="wrapper">
        <div class="wrapperHeader">
            <div class="wrapIconHeader" @click="$router.go(-1)">
                <font-awesome-icon icon="chevron-left" />
            </div>
            <h3>
                Hasil Pemeriksaan
            </h3>
        </div>
    </div>
    <div class="header">
        <div class="rowHeaderWrap">
            <div class="rowHeaderWrapImage">
                <v-img src="@/assets/default_profile.png" class="imgCenter" alt="" cover>
                </v-img>
            </div>
            <div class="rowHeaderWrapContent">
                <li>
                    <h4>{{ this.fullName }}</h4>
                    <p v-if="this.gender == 'FEMALE'">Perempuan <font-awesome-icon icon="circle" class="icon-grey-small" /> {{ this.tglLahir }}</p>
                    <p v-else>Laki-Laki <font-awesome-icon icon="circle" class="icon-grey-small" /> {{ this.tglLahir }}</p>
                </li>
            </div>
        </div>
    </div>
    <div class="tabWrapper" v-if="flagActive" @click="changeFlagActive">
        <div class="backgroundTabActive">
            <h3>Hasil</h3>
        </div>
        &nbsp;
        <div class="backgroundTab">
            <h3>Tabel KMS</h3>
        </div>
    </div>
    <div class="tabWrapper" v-else @click="changeFlagActive">
        <div class="backgroundTab">
            <h3>Hasil</h3>
        </div>
        &nbsp;
        <div class="backgroundTabActive">
            <h3>Tabel KMS</h3>
        </div>
    </div>
    <div class="dataSection">
        <div class="infinite-scroll-list" ref="scrollContainer" 
            @scroll="handleScroll" v-if="flagActive">
            <ul>
                <li v-for="data in dataHasil" :key="data.measurementDate" style="list-style: none;">
                    <div class="backgroundRowData" @click="redirectToDetail(data)">
                        <p style="color: black;">
                            {{ this.formatedDate(data.measurementDate) }}
                        </p>
                        <v-divider style="margin-top: 5px; margin-bottom: 10px; 
                            color: grey;"></v-divider>
                        <table class="dataTable">
                            <tr>
                                <td style="color: grey; width: 100px;">Usia</td>
                                <td>
                                    <h3 v-if="data.age.years > 0 && data.age.totalMonths > 0 && data.age.months > 0">{{ data.age.totalMonths }} Bulan atau {{ data.age.years }} Tahun {{ data.age.months }} Bulan</h3>
                                    <h3 v-else-if="data.age.years > 0 && data.age.totalMonths > 0 && data.age.months <= 0">{{ data.age.totalMonths }} Bulan atau {{ data.age.years }} Tahun</h3>
                                    <h3 v-else>{{ data.age.totalMonths }} Bulan</h3>
                                </td>
                                <td rowspan="7">
                                    <font-awesome-icon icon="chevron-right" class="colorIcon" />
                                </td>
                            </tr>
                            <tr>
                                <td style="color: grey; width: 100px;">Berat Badan</td>
                                <td><h3>{{ data.weightBodyKg }} kg</h3></td>
                            </tr>
                            <tr v-if="data.age.totalMonths > 24">
                                <td style="color: grey; width: 100px;">Tinggi Badan</td>
                                <td>
                                    <h3>{{ data.heightBodyCm }} cm</h3>
                                </td>
                            </tr>
                            <tr v-else>
                                <td style="color: grey; width: 100px;">Panjang Badan</td>
                                <td>
                                    <h3>{{ data.heightBodyCm }} cm</h3>
                                </td>
                            </tr>
                            <tr>
                                <td style="color: grey; width: 100px; font-weight: bold;">Status Gizi:</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style="color: grey; width: 100px;">BB/U</td>
                                <td>
                                    <h3 style="color: #18AF7C;" v-if="data.bbPerUmNutritionalStatusCategory == 'BB Normal'">
                                        {{ data.bbPerUmNutritionalStatusCategory }}
                                    </h3>
                                    <h3 style="color: #FE9800;" v-else-if="data.bbPerUmNutritionalStatusCategory == 'BB Kurang'">
                                        {{ data.bbPerUmNutritionalStatusCategory }}
                                    </h3>
                                    <h3 style="color: #D3555C;" v-else>
                                        {{ data.bbPerUmNutritionalStatusCategory }}
                                    </h3>
                                </td>
                            </tr>
                            <tr>
                                <td style="color: grey; width: 100px;">PB/U</td>
                                <td>
                                    <h3 style="color: #18AF7C;" v-if="data.pbPerUmOrTbPerUmNutritionalStatusCategory == 'Normal' || data.pbPerUmOrTbPerUmNutritionalStatusCategory == 'Tinggi'">
                                        {{ data.pbPerUmOrTbPerUmNutritionalStatusCategory }}
                                    </h3>
                                    <h3 style="color: #FE9800;" v-else-if="data.pbPerUmOrTbPerUmNutritionalStatusCategory == 'Pendek'">
                                        {{ data.pbPerUmOrTbPerUmNutritionalStatusCategory }}
                                    </h3>
                                    <h3 style="color: #D3555C;" v-else>
                                        {{ data.pbPerUmOrTbPerUmNutritionalStatusCategory }}
                                    </h3>
                                </td>
                            </tr>
                            <tr>
                                <td style="color: grey; width: 100px;">BB/PB</td>
                                <td>
                                    <h3 style="color: #18AF7C;" v-if="data.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Gizi Baik'">
                                        {{ data.bbPerPbOrBbPerTbNutritionalStatusCategory }}
                                    </h3>
                                    <h3 style="color: #FE9800;" v-else-if="data.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Gizi Kurang' || data.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Gizi Lebih' || data.bbPerPbOrBbPerTbNutritionalStatusCategory == 'Beresiko Gizi Lebih'">
                                        {{ data.bbPerPbOrBbPerTbNutritionalStatusCategory }}
                                    </h3>
                                    <h3 style="color: #D3555C;" v-else>
                                        {{ data.bbPerPbOrBbPerTbNutritionalStatusCategory }}
                                    </h3>
                                </td>
                            </tr>
                            <tr>
                                <td style="color: grey; width: 100px;">N/T</td>
                                <td v-if="data.growthStatus == 'Naik'"><h3 style="color: #18AF7C;">{{ data.growthStatus }}</h3></td>
                                <td v-else><h3 style="color: #D3555C;">{{ data.growthStatus }}</h3></td>
                            </tr>
                        </table>
                    </div>
                </li>
            </ul>
            <div v-if="loading" class="loading-indicator">Loading...</div>
        </div>
        <div class="data-table" v-else>
            <div class="table-row">
                <div class="table-cell-header">Umur</div>
                <div class="table-cell" style="border-bottom: none;" v-for="(data, index) in listAges" :key="index"> {{ data }} 
                    <font-awesome-icon v-if="listNote[index] != ''" icon="circle-info" 
                        style="color: #D3555C; margin-left: 5px;" @click="showDialogs(index)" />
                </div>
            </div>
            <div class="table-row">
                <div class="table-cell-header">Bulan Timbang</div>
                <div class="table-cell" style="border-bottom: none;" v-for="data in listBt"> {{ data }}</div>
            </div>
            <div class="table-row">
                <div class="table-cell-header">BB (kg)</div>
                <div class="table-cell" style="border-bottom: none;" v-for="data in listBb"> {{ data }}</div>
            </div>
            <div class="table-row">
                <div class="table-cell-header">KBM (gr)</div>
                <div class="table-cell" style="border-bottom: none;" v-for="data in listKbm"> {{ data }}</div>
            </div>
            <div class="table-row">
                <div class="table-cell-header">N/T</div>
                <div class="table-cell" style="border-bottom: none;" v-for="data in listNt"> {{ data }}</div>
            </div>
            <div class="table-row">
                <div class="table-cell-header">ASI Eksklusif</div>
                <div class="table-cell" v-for="data in listAsi">
                    <font-awesome-icon v-if="data == false" icon="close" />
                    <font-awesome-icon v-else-if="data" icon="check" class="colorCheck" />
                </div>
            </div>
        </div>
    </div>
    <v-dialog v-model="isShowDialog" maxWidth="360px" persistent>
        <v-card>
            <v-card-text>
            <div>
                <p style="font-size: 12px; text-align: center; margin-left: 20px; margin-right: 20px;">
                    {{ this.notes }}
                </p>
            </div>
            </v-card-text>
            <v-btn class="custom-btn-next" style="color: #00CC6A;" elevation="0" @click="closeDialog">Oke</v-btn>
        </v-card>
    </v-dialog>
</template>

<script>
    import { ref } from 'vue'
    import ApiService from '@/service/ApiServices.js'

    export default {
        setup() {
            const dataHasil = ref([])
            var listAges = []
            var listBt = []
            var listBb = []
            var listKbm = []
            var listNt = []
            var listAsi = []
            var listNote = []
            return { dataHasil, listAges, 
                listBt, listBb, listKbm, listNt, listAsi, listNote }
        },
        data() {
            var data = this.$route.query
            const tempBirthdate = this.formatedBirthDate(data['birthDate'])
            return {
                flagActive: true,
                loading: true,
                currentPage: 0,
                isLastPage: false,
                fullName: data['patientFullName'],
                gender: data['gender'],
                tglLahir: tempBirthdate,
                healthCenterId: data['healthCenterId'],
                medicalRecordNumber: data['medicalRecordNumber'],
                measurementDate: data['measurementDate'],
                isShowDialog: false,
                notes: ''
            };
        },
        methods: {
            changeFlagActive() {
                this.currentPage = 0
                this.isLastPage = false
                this.flagActive = !this.flagActive
                if(!this.flagActive) {
                    this.getDataKMS()
                } else {
                    this.getDataHasil()
                }
            },
            redirectToDetail(data) {
                this.$router.push({
                    name: 'detailPemeriksaan',
                    params: {
                        growthMeasurementId: data.growthMeasurementId
                    }
                });
            },
            getDataKMS() {
                this.loading = true;
                ApiService.getDataKMS(this.medicalRecordNumber)
                    .then((response) => {
                        var dataKMS = response.data.data

                        this.listAges = []
                        this.listBt = []
                        this.listBb = []
                        this.listKbm = []
                        this.listNt = []
                        this.listAsi = []
                        this.listNote = []
                        for(var i = 0; i < dataKMS.length; i++) {
                            this.listAges.push(i)
                            if(dataKMS[i] != null) {
                                if(dataKMS[i]['measurementDate'] != null && dataKMS[i]['measurementDate'] != '') {
                                    var dates = new Date(dataKMS[i]['measurementDate'])
                                    let dateFormat = ((dates.getMonth() + 1 > 9) ? (dates.getMonth() + 1) : ('0' + (dates.getMonth() + 1))) + '/' + dates.getFullYear().toString().substring(2)
                                    this.listBt.push(dateFormat)
                                } else {
                                    this.listBt.push('-')
                                }
                                this.listBb.push((dataKMS[i]['weightBodyKg'] != null && dataKMS[i]['weightBodyKg'] != '')? dataKMS[i]['weightBodyKg']: '-')
                                this.listKbm.push((dataKMS[i]['kbmInGr'] != null && dataKMS[i]['kbmInGr'] != '')? dataKMS[i]['kbmInGr']: '-')
                                if(dataKMS[i]['growthStatus'] != null && dataKMS[i]['growthStatus'] != '' && dataKMS[i]['growthStatus'] != '-') {
                                    this.listNt.push((dataKMS[i]['growthStatus'] == 'Tidak')? 'T': 'N')
                                } else {
                                    this.listNt.push('-')
                                }
                                this.listAsi.push((dataKMS[i]['isExclusiveBreastfeeding'] != null)? dataKMS[i]['isExclusiveBreastfeeding']: null)
                                this.listNote.push((dataKMS[i]['note'] != null)? dataKMS[i]['note'] : '')
                            } else {
                                this.listBt.push('-')
                                this.listBb.push('-')
                                this.listKbm.push('-')
                                this.listNt.push('-')
                                this.listAsi.push(null)
                                this.listNote.push('')
                            }
                        }

                        this.loading = false;
                        console.log(this.listAsi)
                    }).catch(error => {
                        console.error(error)
                        this.loading = false;
                    })
            },
            getDataHasil() {
                ApiService.getDataPemeriksaan(this.currentPage, this.medicalRecordNumber)
                    .then((response) => {
                        this.dataHasil = []
                        this.dataHasil = ref(response.data.data.content)
                        
                        this.currentPage++
                        if(this.dataHasil.length < 20) {
                            this.isLastPage = true;
                        } else {
                            this.isLastPage = false;
                        }
                        this.loading = false;
                    }).catch(error => {
                        console.error(error)
                        this.loading = false;
                    })
            },
            formatedDate(tanggal) {
                var dates = new Date(tanggal);
                const options = {
                    weekday: 'long',
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                };
                return dates.toLocaleDateString('id-ID', options);
            },
            formatedBirthDate(tanggal) {
                var dates = new Date(tanggal);
                const options = {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                };
                return dates.toLocaleDateString('id-ID', options);
            },
            handleScroll(event) {
                const scrollContainer = event.target;
                if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) { 
                this.loadMoreData();
                }
            },
            async loadMoreData() {
                if(!this.isLastPage) {
                    try {
                        ApiService.getDataPemeriksaan(this.currentPage, this.medicalRecordNumber).then((res) => {
                            this.dataHasil = this.dataHasil.concat(res.data.data.content)
                            this.currentPage++
                            if(res.data.data.content.length < 20) {
                                this.isLastPage = true;
                            } else {
                                this.isLastPage = false;
                            }
                        }).catch(error => {
                            console.error(error)
                        })
                    } catch (error) {
                        console.error('Error loading next page:', error);
                    }   
                }
            },
            closeDialog() {
                this.isShowDialog = false;
            },
            showDialogs(indeks) {
                this.notes = this.listNote[indeks]
                this.isShowDialog = true;
            }
        },
        mounted() {
            this.getDataHasil()
            this.getDataKMS()
        }
    }
</script>

<style>
    .wrapperHeader {
        display: flex;
        width: 100%;
    }
    .wrapIconHeader {
        align-items: center;
        width: 24px;
        height: 24px;
        margin-top: 2px;
    }
    .header {
        background-color: rgba(243, 241, 239, 0.5);
        width: 100%;
        padding: 10px 20px 10px 20px;
        max-width: 960px;
        margin: 0 auto;
        font-size: .8rem;
    }
    .rowHeaderWrap {
        display: flex;
        width: 100%;
    }
    .rowHeaderWrapImage {
        align-items: center;
        width: 42px;
        height: 42px;
        margin-left: 10px;
    }
    .rowHeaderWrapContent {
        margin-left: 20px;
    }
    .rowHeaderWrapContent li {
        list-style: none; 
    }
    .rowHeaderWrapContent li h4 {
        font-size: 14px;
    }
    .rowHeaderWrapContent li p {
        font-size: 12px;
    }
    .tabWrapper {
        display: flex;
        width: 100%;
        padding: 10px 20px 10px 20px;
        max-width: 960px;
        margin: 0 auto;
        font-size: .8rem;
    }
    .backgroundTab {
        padding: 5px 15px 5px 15px;
        border-radius: 20px;
        border: 1px solid lightgrey;
        color: black;
    }
    .backgroundTab h3 {
        font-size: 12px;
    }
    .backgroundTabActive {
        padding: 5px 15px 5px 15px;
        border-radius: 20px;
        border: 1px solid #2B92E4;
        color: #2B92E4;
    }
    .backgroundTabActive h3 {
        font-size: 12px;
    }
    .dataSection {
        display: flex;
        width: 100%;
        padding: 10px;
        max-width: 960px;
        margin: 0 auto;
        font-size: .8rem;
    }
    .backgroundRowData {
        padding: 10px 10px 10px 20px;
        border-radius: 8px;
        box-shadow: 0 0 4px lightgrey;
        margin-bottom: 10px;
        font-size: 14px;
        max-width: 380px;
    }
    .dataTable, h3 {
        width: 100%;
        border-collapse: collapse;
        font-size: 14px;
        text-align: left;
    }
    .colorIcon {
        color: grey;
    }
    .data-table {
        width: 100%;
        padding: 20px 20px 20px 10px;
        overflow-x: auto; /* Enable horizontal scrolling on small screens */
        border-collapse: collapse;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    .data-table tr, .data-table td {
        border-right: 1px solid lightgrey;
        border-bottom: 1px solid lightgrey;
        padding: 10px 15px 10px 15px;
        text-align: center;
        color: black;
        font-size: 12px;
    }
    .no-border {
        border-left: none;
    }
    .colorCheck {
        color: #00CC6A;
    }
    .infinite-scroll-list {
        width: 100%;
        min-height: 400px;
        max-height: 750px;
        overflow-y: auto;
        padding: 10px;
    }

    .table-container {
        display: table;
        width: 100%;
    }

    .table-row {
        display: table-row;
    }

    .table-cell {
        display: table-cell;
        min-width: 60px;
        max-width: 60px;
        height: 55px;
        border: 1px solid #ccc;
        text-align: center;
    }

    .table-cell-header {
        display: table-cell;
        padding: 10px 20px 10px 10px;
        min-width: 80px;
        max-width: 80px;
        height: 55px;
        border-right: 1px solid #ccc;
    }

    .custom-btn-next {
        border: 1px solid #00CC6A;
        border-radius: 8px;
        background-color: transparent;
        color: #00CC6A;
        display: block; 
        margin: 10px 45px 20px 45px;
        text-transform: none;
        transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Add smooth transitions */
    }
</style>